import React from "react";
import classNames from "classnames";

import { PlaceholderImage } from "../assets/svg";

function Card({
  card,
  onClick,
  index,
  isInactive,
  isDisabled,
  isSelected,
  isCorrect,
  isFail,
}) {
  let element;
  if (card.image === null && card.image_class) {
    element = <PlaceholderImage aria-hidden="true" />;
  } else {
    element = <img src={card.image} alt="" aria-hidden="true" />;
  }

  const handleClick = (e) => {
    !isDisabled && onClick(index);
  };

  const handleKeyPress = (e) => {
    e.preventDefault();
    var code = e.charCode || e.keyCode;
    if (code === 32 || code === 13) {
      !isDisabled && onClick(index);
    } else {
      return false;
    }
  };

  return (
    <>
      <li className="deck--list-item">
        <button
          className={classNames("card", {
            "is-inactive": isInactive,
            "is-selected": isSelected,
            match: isCorrect,
            "no-match": isFail,
            person: card.image_class,
          })}
          id={card.id}
          type={card.type}
          onClick={!isInactive || card.image_class ? handleClick : null}
          onKeyPress={handleKeyPress}
        >
          {element}
          <span className="card--text">{card.name}</span>
        </button>
      </li>
    </>
  );
}

export default Card;
