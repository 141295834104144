import React from "react";

//@material-ui
import { makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  infoAlert: {
    fontSize: "1em",
    paddingBlockStart: "0.5rem",
    width: "50%",
    marginInline: "auto",
    "& .MuiAlert-icon": {
      fontSize: 30,
    },
    "& .MuiAlert-message": {
      color: "#000",
      "& li": {
        paddingBlockEnd: "0.5rem",
      },
    },
  },
}));

const Page404 = ({ chosenGame }) => {
  const classes = useStyles();
  return (
    <div className="game_view--not_found">
      <Alert severity="info" className={classes.infoAlert}>
        Kunde inte ladda spel {chosenGame}. <br />
        Något utav följande kan ha hänt:
        <ul>
          <li>Spelet finns, men inga politiker är ännu tillagda.</li>
          <li>
            Spelet finns inte, eller är felstavat. <br />
            Prova med att{" "}
            <a className="link" href="/alla-spel">
              kolla i listan om spelet finns.
            </a>
          </li>
        </ul>
      </Alert>
    </div>
  );
};

export default Page404;
