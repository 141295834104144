const useConvertSlug = () => {
  const convertFromSlug = (string) => {
    let result;
    if (string.indexOf("-") >= 0) {
      const subStrOne = string.substring(0, string.indexOf("-"));
      const beginning = subStrOne.charAt(0).toUpperCase() + subStrOne.slice(1);

      const subStrTwo = string.split("-").pop();
      const end = subStrTwo.charAt(0).toUpperCase() + subStrTwo.slice(1);

      result = beginning + " " + end;
    } else {
      result = string.charAt(0).toUpperCase() + string.slice(1);
    }
    return result;
  };

  const convertToSlug = (string) => {
    let result;
    if (string.indexOf(" ") >= 0) {
      const subStrOne = string.substring(0, string.indexOf(" "));
      const beginning = subStrOne.charAt(0).toLowerCase() + subStrOne.slice(1);

      const subStrTwo = string.split(" ").pop();
      const end = subStrTwo.charAt(0).toLowerCase() + subStrTwo.slice(1);

      result = beginning + "-" + end;
    } else {
      result = string.charAt(0).toLowerCase() + string.slice(1);
    }
    return result;
  };

  return {
    convertFromSlug,
    convertToSlug,
  };
};
export default useConvertSlug;
