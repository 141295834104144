export function transformData(data, logo) {
  var result;

  if (data !== undefined) {
    const dataArray = data.map((obj) => {
      return {
        id: obj._id,
        type: obj.party,
        image: obj.image,
        name: obj.name,
        fail: false,
        complete: false,
        image_class: "person",
      };
    });

    const logoExists = (type) => {
      return logo.some((el) => {
        return el.type === type;
      });
    };

    let tempArray = dataArray.map((x, index) => {
      let partiLogo;
      if (!logoExists(x.type)) {
        partiLogo = {
          id: index + 100,
          type: x.type,
          name: x.type,
          image: "",
          fail: false,
          complete: false,
        };
      } else {
        const item = logo.find((item) => item.type === x.type);
        partiLogo = {
          id: index,
          type: item.type,
          name: item.type,
          image: item.image,
          fail: false,
          complete: false,
        };
      }

      return partiLogo;
    });

    result = dataArray.concat(tempArray);
  }

  return result;
}
