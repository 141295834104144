import React from "react";
import { useHistory } from "react-router";

import { Cards, CardsReverse, Click } from "../assets/svg";

const InstructionsList = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/spel/nationellt");
  };
  return (
    <section className="description_section" aria-label="Spelinstruktioner">
      <h2 className="instructions_title">
        Para ihop politikerna med rätt parti
      </h2>
      <ul className="instructions_list">
        <li className="instructions_list_item">
          <div className="instruction">
            <p>Klicka på en politiker och sen på det du tror är rätt parti.</p>
          </div>
          <Cards className="instruction-card_svg" aria-hidden="true" />
        </li>
        <li className="instructions_list_item">
          <div className="instruction">
            <p>
              Eller tvärtom, börja med att klicka på en partisymbol och välj
              sedan en politiker.
            </p>
          </div>
          <CardsReverse className="instruction-card_svg" aria-hidden="true" />
        </li>
        <li className="instructions_list_item">
          <div className="instruction">
            <p>
              Vill du <strong>ändra dig</strong> klickar du på politikern en
              gång till och gör om.
            </p>
          </div>
          <Click className="instruction-card_svg" aria-hidden="true" />
        </li>
      </ul>
      <p>
        Du kan välja att spela på <strong>nationell nivå</strong> eller på{" "}
        <strong>lokal nivå</strong>.
      </p>
      <a className="link" href="/alla-spel">
        Kolla i listan om just din kommun finns med.
      </a>
      <button className="active_button" onClick={handleClick}>
        Starta spel på nationell nivå
      </button>
    </section>
  );
};

export default InstructionsList;
