import React from "react";

const Footer = () => {
  return (
    <footer className="page_footer">
      <a href="https://www.mittval.nu" aria-label="Mittval.nu">
        <img src="https://www.mittval.nu/images/mittval_logo1.png" alt="" />
      </a>
    </footer>
  );
};

export default Footer;
