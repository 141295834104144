import React, { useEffect, useState, useRef, useContext } from "react";

//Hooks
import { shuffle } from "../hooks/useShuffle";

//Context
import { GameContext } from "../context/GameContext";

//Components
import Card from "./Card";
import { ResultModal } from "./common/Modal";

//@material-ui
import { Button } from "@material-ui/core";

function Deck() {
  const { setCorrect, data } = useContext(GameContext);
  const [cards, setCards] = useState(data);
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState([]);
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const timeout = useRef(null);

  const checkCompletion = () => {
    if (
      Object.keys(clearedCards).length * 2 === cards.length &&
      cards.length > 0
    ) {
      countScore();
      setShowModal(true);
    }
  };

  const countScore = () => {
    var j = 0;
    for (var i in clearedCards) {
      if (clearedCards[i].matched) {
        j++;
      }
    }
    setCorrect(j);
  };

  const disable = () => {
    setShouldDisableAllCards(true);
  };

  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].id !== cards[second].id) {
      if (cards[first].type === cards[second].type) {
        cards[first].complete = true;
        cards[second].complete = true;
        setClearedCards((prev) => [
          ...prev,
          {
            matched: true,
            cards: [cards[first].id, cards[second].id],
          },
        ]);
      } else {
        cards[first].fail = true;
        cards[second].fail = true;
        setClearedCards((prev) => [
          ...prev,
          {
            matched: false,
            cards: [cards[first].id, cards[second].id],
          },
        ]);
      }
      setOpenCards([]);
      return;
    } else {
      timeout.current = setTimeout(() => {
        setOpenCards([]);
      }, 500);
    }
  };

  const handleCardClick = (index) => {
    // Have a maximum of 2 items in array at once.
    if (openCards.length === 1) {
      //@desc Deselect first card if clicked again
      if (cards[openCards[0]].id === cards[index].id) {
        setOpenCards([]);
        return;
      }
      //@desc If the first card is a person, then the second card can not be a person.
      else if (
        (cards[openCards[0]].image_class && cards[index].image_class) ||
        (!cards[openCards[0]].image_class && !cards[index].image_class)
      ) {
        return;
      } else {
        setOpenCards((prev) => [...prev, index]);
        disable();
      }
    } else {
      //@desc remove a pair if the person is clicked again
      if (clearedCards.length > 0 && checkIfCleared(index) !== -1) {
        removePair(checkIfCleared(index));
        setOpenCards([]);
        return;
      }
      // If two cards are already open, we cancel timeout set for flipping cards back
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  const removePair = (idIndex) => {
    var idArray = clearedCards[idIndex].cards;
    let id1 = cards.find((o) => o.id === idArray[0]);
    if (id1.complete) id1.complete = false;
    if (id1.fail) id1.fail = false;
    let id2 = cards.find((o) => o.id === idArray[1]);
    if (id2.complete) id2.complete = false;
    if (id2.fail) id2.fail = false;
    var filtered = clearedCards.filter((value, index, arr) => {
      return index !== idIndex;
    });
    setClearedCards(filtered);
  };

  const checkIfCleared = (index) => {
    var idIndex = clearedCards.findIndex((o) => {
      return o.cards.includes(cards[index].id);
    });
    return idIndex;
  };

  const checkIsSelected = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(card.complete || card.fail);
    // return Boolean(clearedCards[card.type]);
  };

  const checkIsCorrect = (card) => {
    return Boolean(card.complete);
  };

  const checkIsFail = (card) => {
    return Boolean(card.fail);
  };

  const restartGame = () => {
    setShowModal(false);
    setOpenCards([]);
    setClearedCards([]);
    setCorrect(0);
    for (var i = 0; i < cards.length; i++) {
      if (cards[i].complete) cards[i].complete = false;
      if (cards[i].fail) cards[i].fail = false;
    }
    setCards(shuffle(data));
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    checkCompletion();
  });

  useEffect(() => {
    setCards(data);
  }, [data]);

  return (
    <>
      {clearedCards.length > 0 && (
        <Button className="restart_btn" onClick={restartGame}>
          Börja om
        </Button>
      )}

      <ul className="deck">
        {cards.map((card, index) => {
          return (
            <Card
              key={index}
              card={card}
              index={index}
              isDisabled={shouldDisableAllCards}
              isFail={checkIsFail(card)}
              isCorrect={checkIsCorrect(card)}
              isInactive={checkIsInactive(card)}
              isSelected={checkIsSelected(index)}
              onClick={handleCardClick}
            />
          );
        })}
      </ul>

      <ResultModal
        open={showModal}
        onClick={restartGame}
        clearedCards={clearedCards}
        cards={cards}
      />
    </>
  );
}

export default Deck;
