import React from "react";

//@material-ui
import {
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
} from "@material-ui/core";

//@context
import { GameContext } from "../../context/GameContext";

export const HelpModal = ({ open, handleClose }) => {
  return (
    <Modal
      role="dialog"
      className="help_modal"
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-help"
      aria-describedby="modal-help-description"
    >
      <div className="modal-content_container">
        <div className="modal_header">
          <h2 id="modal-help">Vem hör till vilket parti?</h2>
          <button
            className="help-modal-close_button sm_btn"
            onClick={() => handleClose(false)}
            aria-label="Stäng spelinstruktioner"
          ></button>
        </div>
        <div className="modal-content">
          <p id="modal-help-description">
            Para ihop politikerna med rätt parti. <br />
            Klicka på en politiker och sen på det du tror är rätt parti. <br />
            Eller tvärtom, börja med att klicka på en partisymbol och välj sedan
            en politiker. <br />
            Om du vill ändra dig klickar du på politikern en gång till och gör
            om.
          </p>
          <h2>Byt spel</h2>
          <p>
            Vill du byta spel och lära dig vilka politiker som finns i din
            kommun eller region?
            <br />
            <a className="link" href="/alla-spel">
              Kolla i listan om din kommun/region finns tillgänglig.
            </a>
          </p>
        </div>
      </div>
    </Modal>
  );
};

export const ResultModal = ({ open, onClick, clearedCards, cards }) => {
  const { correct } = React.useContext(GameContext);

  const restart = (e) => {
    open && onClick(e);
  };

  const close = () => {
    window.location.href = "http://mittval.nu";
  };

  const resultItem = (arr) => {
    var imgArr = [];
    const [first, second] = arr;
    var img1 = cards.find((o) => o.id === first);
    var img2 = cards.find((o) => o.id === second);
    if (img1.image_class) {
      imgArr.push([img1.image, img1.name]);
      imgArr.push([img2.image, img2.name]);
    } else {
      imgArr.push([img2.image, img2.name]);
      imgArr.push([img1.image, img1.name]);
    }
    return imgArr;
  };

  return (
    <Dialog
      className="result_modal"
      open={open}
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Ditt resultat</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          <strong>Bra jobbat!</strong> Du fick <strong>{correct} rätt</strong>{" "}
          av {clearedCards.length} möjliga
          <br />
          Utmana dig själv och spela igen eller gå tillbaka till Mittval genom
          att avsluta spelet.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="button_container">
        <Button className="btn modal_btn" onClick={restart}>
          Spela igen!
        </Button>
        <Button className="btn modal_btn" onClick={close}>
          Avsluta spel
        </Button>
      </DialogActions>
      <DialogContent className="result-list_container">
        {correct > 0 ? (
          <DialogContentText id="dialog-description">
            Dina rätta svar
          </DialogContentText>
        ) : null}
        <ul className="result-list">
          {clearedCards.map((obj, index) => {
            if (obj.matched) {
              const result = resultItem(obj.cards);
              return (
                <li key={index} className="result-list_item">
                  <div className="column">
                    <p>{result[0][1]}</p>
                    <img src={result[0][0]} alt="" />
                  </div>
                  <div className="column">
                    <p>{result[1][1]}</p>
                    <img src={result[1][0]} alt="" />
                  </div>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </DialogContent>
    </Dialog>
  );
};
