const useSeo = () => {
  const seo = (data = {}) => {
    data.title = data.title || "Mitt val | Gissa partiet";
    data.metaDescription =
      data.metaDescription ||
      "Lär känna de aktuella politikerna i de olika partierna som sitter i riksdagen, de som styr i din kommun eller region.";

    document.title = data.title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", data.metaDescription);
  };
  return { seo };
};

export default useSeo;
