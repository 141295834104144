import React from "react";
import ListItem from "./ListItem";

import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  infoAlert: {
    fontSize: "1em",
    "& .MuiAlert-icon": {
      fontSize: 30,
    },
  },
}));

const List = ({ avaliableGames, handleChange }) => {
  const classes = useStyles();
  var sortedArray = avaliableGames.sort((a, b) =>
    a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
  );

  return (
    <ul className="games--list">
      {sortedArray.length < 1 && (
        <Alert severity="info" className={classes.infoAlert}>
          Kunde inte hitta kommunen eller regionen du sökte på.
        </Alert>
      )}
      {sortedArray.map((obj, index) => {
        return <ListItem key={index} obj={obj} />;
      })}
    </ul>
  );
};

export default List;
