import React from "react";
import { Person } from "../assets/svg";

const Herospace = () => {
  return (
    <div className="header_herospace">
      <Person className="person_svg" aria-hidden="true" alt="" />
      <div className="herospace">
        <h1 className="herospace_title">Gissa partiet</h1>
        <p className="herospace_text">Vem hör till vilket parti?</p>
      </div>
    </div>
  );
};

export default Herospace;
