import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Deck from "../components/Deck";
import LoadingSpinner from "../components/common/LoadingSpinner";

import { GameContext } from "../context/GameContext";

import useConvertSlug from "../hooks/useConvertSlug";
import useSeo from "../hooks/useSeo";

import Page404 from "../components/Page404";

function GameView(props) {
  const { setChosenGame, data, isLoading } = useContext(GameContext);
  const [gameName] = useState(props.match.params.name);
  const { convertFromSlug } = useConvertSlug();
  const { seo } = useSeo();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setChosenGame(convertFromSlug(gameName));
      seo({
        title: `Mitt val | Gissa partiet | ${gameName}`,
        metaDescription: `Para ihop politikerna med sitt rätta parti i ${gameName}`,
      });
    }
    return () => {
      mounted = false;
    };
  }, [gameName, setChosenGame, convertFromSlug, seo]);

  if (!data.length > 0 && !isLoading) {
    return <Page404 />;
  }

  return (
    <main className="game_view--container">
      <section className="game_section--container" aria-label="Spel">
        {isLoading && (
          <div className="center">
            <LoadingSpinner asOverlay />
          </div>
        )}
        {!isLoading && data.length > 0 && <Deck />}
      </section>
    </main>
  );
}

export default withRouter(GameView);
