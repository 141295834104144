import React, { useState, useEffect, createContext, memo } from "react";

import { transformData } from "../hooks/useData";
import { shuffle } from "../hooks/useShuffle";

import { useHttpClient } from "../hooks/httpHook";

import { logga } from "../assets/data/nationellt";

export const GameContext = createContext([{}, () => {}]);

export const GameProvider = memo(({ children }) => {
  const { sendRequest, isLoading } = useHttpClient();
  const [avaliableGames, setAvaliableGames] = useState([]);
  const [chosenGame, setChosenGame] = useState("Nationellt");
  const [data, setData] = useState([]);
  const [game, setGame] = useState([]);
  const [correct, setCorrect] = useState(0);

  useEffect(() => {
    const fetchAvaliableGames = async () => {
      try {
        const responseData = await sendRequest(
          "https://mittval-api.herokuapp.com/api/game"
        );
        setAvaliableGames(responseData.places);
      } catch (err) {}
    };
    fetchAvaliableGames();
  }, [sendRequest]);

  useEffect(() => {
    const filterGameTitle = (string, attribute) => {
      const termLowerCase = string.toLowerCase();
      return (avaliableGames) =>
        Object.keys(avaliableGames).some(
          () =>
            avaliableGames[attribute].toLowerCase().indexOf(termLowerCase) !==
            -1
        );
    };

    const fetchChosenGame = async () => {
      const game = avaliableGames.filter(filterGameTitle(chosenGame, "title"));

      try {
        const responseData = await sendRequest(
          "https://mittval-api.herokuapp.com/api/game/play",
          "GET",
          null,
          {},
          {
            name: game[0].title,
            type: game[0].type,
          }
        );

        const transformedData = transformData(
          responseData.place.politicians,
          logga
        );
        setData(shuffle(transformedData));
      } catch (err) {}
    };
    fetchChosenGame();
  }, [sendRequest, chosenGame, avaliableGames]);

  return (
    <GameContext.Provider
      value={{
        chosenGame,
        setChosenGame,
        game,
        setGame,
        correct,
        setCorrect,
        avaliableGames,
        data,
        isLoading,
      }}
    >
      {children}
    </GameContext.Provider>
  );
});
