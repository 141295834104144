import React, { useContext, useState } from "react";
import List from "../components/AvaliableGames/List";
import AutoSuggest from "react-autosuggest";
import LoadingSpinner from "../components/common/LoadingSpinner";

import { GameContext } from "../context/GameContext";

const AvaliableGames = () => {
  const { avaliableGames, isLoading } = useContext(GameContext);

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : avaliableGames.filter((item) =>
          item.title.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.

  const getSuggestionValue = (suggestion) => suggestion.title;

  const renderSuggestion = (suggestion) => <span>{suggestion.title}</span>;

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchedRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: "Sök kommun eller region",
    type: "search",
    className: "search--autosuggest",
    value: value || "",
    onChange: onChange,
  };

  let filteredItems = avaliableGames.filter((item) =>
    item.title.toLocaleLowerCase().includes(value.toLocaleLowerCase())
  );

  return (
    <main className="avaliable_games--view">
      <div className="avaliable_games--header">
        <h1>Hitta din lokala nivå</h1>

        <div className="search--container">
          <label>
            Sök efter din kommun/region
            <AutoSuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchedRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
            />
          </label>
        </div>
      </div>
      <div className="avaliable_games--info">
        <p>Välj en kommun/region i listan för att starta ett spel.</p>
        <p>
          Du kan söka efter en viss kommun/region i sökfältet. <br /> Listan
          kommer då att uppdateras och visa om den kommunen/regionen finns
          tillgänglig.
        </p>
      </div>
      <div className="list--header">
        <h2>Kommuner/regioner</h2>
      </div>
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && <List avaliableGames={filteredItems} />}
    </main>
  );
};

export default AvaliableGames;
