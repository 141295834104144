import React from "react";
import ItemLink from "./ItemLink";

const ListItem = ({ obj }) => {
  return (
    <li>
      <ItemLink obj={obj} type="list" />
    </li>
  );
};

export default ListItem;
