import s_logga from "../images/socialdemokraterna_logga.png";
import c_logga from "../images/centerpartiet_logga.png";
import v_logga from "../images/vänsterpartiet_logga.png";
import m_logga from "../images/moderaterna_logga.png";
import mp_logga from "../images/miljöpartiet_logga.png";
import sd_logga from "../images/sd_logo.png";
import l_logga from "../images/liberalerna_logga.png";
import kd_logga from "../images/kd_logga.png";

export const logga = [
  {
    id: 10,
    type: "Socialdemokraterna",
    name: "Socialdemokraterna",
    image: s_logga,
    fail: false,
    complete: false,
  },
  {
    id: 11,
    type: "Centerpartiet",
    name: "Centerpartiet",
    image: c_logga,
    fail: false,
    complete: false,
  },
  {
    id: 12,
    type: "Vänsterpartiet",
    name: "Vänsterpartiet",
    image: v_logga,
    fail: false,
    complete: false,
  },
  {
    id: 13,
    type: "Moderaterna",
    name: "Moderaterna",
    image: m_logga,
    fail: false,
    complete: false,
  },
  {
    id: 14,
    type: "Miljöpartiet",
    name: "Miljöpartiet",
    image: mp_logga,
    fail: false,
    complete: false,
  },
  {
    id: 15,
    type: "Sverigedemokraterna",
    name: "Sverigedemokraterna",
    image: sd_logga,
    fail: false,
    complete: false,
  },
  {
    id: 16,
    type: "Liberalerna",
    name: "Liberalerna",
    image: l_logga,
    fail: false,
    complete: false,
  },
  {
    id: 17,
    type: "Kristdemokraterna",
    name: "Kristdemokraterna",
    image: kd_logga,
    fail: false,
    complete: false,
  },
  {
    id: 18,
    type: "Miljöpartiet",
    name: "Miljöpartiet",
    image: mp_logga,
    fail: false,
    complete: false,
  },
];
