import React from "react";

import useConvertSlug from "../../hooks/useConvertSlug";

import { ArrowRight } from "../../assets/svg";

const ItemLink = ({ obj, type }) => {
  const { convertToSlug } = useConvertSlug();
  return (
    <a
      href={`/spel/${convertToSlug(obj.title)}`}
      aria-label={obj.title}
      className={`list_item--link ${type}`}
    >
      {obj.title}
      <ArrowRight alt="" aria-hidden="true" />
    </a>
  );
};

export default ItemLink;
