import React, { useState } from "react";
import { Logo } from "../../assets/svg";

import { HelpModal } from "./Modal";

const Header = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <header className="header">
        <a
          className="header__startpage-link"
          href="/"
          aria-label="Till startsidan"
        >
          <Logo className="logo_svg" alt="Logotyp för Gissa partiet" />
        </a>
        <a href="https://www.mittval.nu" className="home_link">
          <span className="home_link__text">Tillbaka till mittval.nu</span>
          <div className="home_link__icon"></div>
        </a>
        <button
          onClick={() => setModalOpen(true)}
          className="header-help_button"
        >
          <span>Hjälp</span>
          <div className="help_icon"></div>
        </button>
      </header>
      <HelpModal open={modalOpen} handleClose={setModalOpen} />
    </>
  );
};

export default Header;
