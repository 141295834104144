import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Route } from "react-router-dom";
import { GameProvider } from "./context/GameContext";
import { createTheme, MuiThemeProvider } from "@material-ui/core";

import Header from "./components/common/Header";
import StartView from "./view/StartView";
import GameView from "./view/GameView";
import AvaliableGames from "./view/AvaliableGames";
import Footer from "./components/common/Footer";

import useSeo from "./hooks/useSeo";

function App() {
  const { seo } = useSeo();

  useEffect(() => {
    seo();
  }, [seo]);

  const theme = createTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
  });

  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <Header />
        <GameProvider>
          <Route exact path="/" component={StartView} />
          <Route path="/spel/:name">
            <GameView />
          </Route>
          <Route path="/alla-spel" component={AvaliableGames} />
        </GameProvider>
        <Footer />
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
