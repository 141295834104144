import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";

// SCC Normalize
import "./index.scss";

// SCC Views
import "./scss/view/AvaliableGames.scss";
import "./scss/view/GameView.scss";
import "./scss/view/StartView.scss";

// SCC Components -- Site
import "./scss/components/Header.scss";
import "./scss/components/Footer.scss";
import "./scss/components/Modal.scss";
import "./scss/components/Button.scss";
import "./scss/components/LoadingSpinner.scss";

// SCC Components -- StartView
import "./scss/components/Herospace.scss";
import "./scss/components/InstructionsList.scss";

// SCC Components -- AvaliableGames
import "./scss/components/Search.scss";
import "./scss/components/ItemLink.scss";

// SCC Components -- GameView
import "./scss/components/Deck.scss";
import "./scss/components/Card.scss";

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
