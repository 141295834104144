import React from "react";

import Herospace from "../components/Herospace";
import InstructionsList from "../components/InstructionsList";

const StartView = () => {
  return (
    <>
      <Herospace />
      <main>
        <div className="start-view_main">
          <InstructionsList />
        </div>
      </main>
    </>
  );
};

export default StartView;
